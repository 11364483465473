import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub } from '@fortawesome/free-brands-svg-icons'

function Project(props) {
  return (
    <div className="m-5">
      <div className="box">
        <h1 className="title project is-size-3 mb-2">
          {props.name}
        </h1>
        <p className="has-text-weight-medium has-text-grey">{props.language}</p>
        <p className="mt-3">
          {props.description}
        </p>

        <div className="mt-5">
          <a href={props.source} target="blank" className="button is-info is-outlined"><FontAwesomeIcon icon={faGithub} className="mr-2" />View Source</a>
        </div>
      </div>
    </div>
  );
}

export default Project;
