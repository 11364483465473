import React from 'react';

const particleCount = 100;
const velocityMin = 30;
const velocityMax = 50;
const distanceMax = 150;
const offscreenMax = 150;

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.canvas = React.createRef();
  }

  render() {
    return (
      <header className="App-header">
        <canvas ref={this.canvas} className="background"></canvas>
        <h1 className="title name is-size-1">Teemu Rautavalta</h1>

        <div class="scroll-downs">
          <div class="mousey">
            <div class="scroller"></div>
          </div>
        </div>
      </header>
    );
  }

  componentDidMount() {
    let resizeCanvas = () => {
      if (!this.canvas.current)
        return;

      this.canvas.current.width = window.innerWidth;
      this.canvas.current.height = window.innerHeight;
    }
    resizeCanvas();
    window.addEventListener('resize', resizeCanvas, false);

    this.ctx = this.canvas.current.getContext("2d");

    this.points = [];
    for (let i = 0; i < particleCount; i++) {
      let point = {
        pos: { x: Math.random() * this.canvas.current.width, y: Math.random() * this.canvas.current.height },
        vel: { x: Math.random() * 2 - 1, y: Math.random() * 2 - 1 }
      };

      let velLen = Math.sqrt(point.vel.x * point.vel.x + point.vel.y * point.vel.y);
      let mutiplier = Math.random() * (velocityMax - velocityMin) + velocityMin;

      point.vel.x = point.vel.x / velLen * mutiplier;
      point.vel.y = point.vel.y / velLen * mutiplier;

      this.points.push(point);
    }

    this.renderCanvas();
  }

  renderCanvas() {
    if (!this.canvas.current)
      return;

    const deltaTime = 1 / 60;

    for (let i = 0; i < this.points.length; i++) {
      const point = this.points[i];

      point.pos.x += point.vel.x * deltaTime;
      point.pos.y += point.vel.y * deltaTime;

      if (point.pos.x > this.canvas.current.width + offscreenMax)
        point.pos.x = -offscreenMax;
      if (point.pos.x < -offscreenMax)
        point.pos.x = this.canvas.current.width + offscreenMax;
        
      if (point.pos.y > this.canvas.current.height + offscreenMax)
        point.pos.y = -offscreenMax;
      if (point.pos.y < -offscreenMax)
        point.pos.y = this.canvas.current.height + offscreenMax;
    }

    let grd = this.ctx.createLinearGradient(0, 0, this.canvas.current.width, this.canvas.current.height);
    grd.addColorStop(0, '#000');
    grd.addColorStop(1, '#222');

    this.ctx.fillStyle = grd;
    this.ctx.fillRect(0, 0, this.canvas.current.width, this.canvas.current.height);

    this.ctx.fillStyle = '#888';
    for (let i = 0; i < this.points.length; i++) {
      const point = this.points[i];

      this.ctx.beginPath();
      this.ctx.arc(point.pos.x, point.pos.y, 2, 0, 2 * Math.PI);
      this.ctx.fill();

      for (let j = i + 1; j < this.points.length; j++) {
        const p = this.points[j];

        const diff = { x: p.pos.x - point.pos.x, y: p.pos.y - point.pos.y };
        const lenSqr = diff.x * diff.x + diff.y * diff.y;

        if (lenSqr < distanceMax * distanceMax) {
          const len = Math.sqrt(lenSqr);
          const alpha = 1 - len / distanceMax;

          //console.log(('0' + (alpha * 100)).slice(-2));
          this.ctx.strokeStyle = '#888888' + ('0' + (Math.round(alpha * 255)).toString(16).slice(0, 2)).slice(-2);

          this.ctx.beginPath();
          this.ctx.moveTo(point.pos.x, point.pos.y);
          this.ctx.lineTo(p.pos.x, p.pos.y);
          this.ctx.stroke();
        }
      }
    }

    requestAnimationFrame(() => this.renderCanvas());
  }
}

export default Home;
