import './App.css';
import 'bulma';

import Home from './Home';
import Projects from './Projects';

function App() {
  return (
    <div className="App">
      <Home />
      <Projects />
    </div>
  );
}

export default App;
