import Project from './Project'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub } from '@fortawesome/free-brands-svg-icons'

function Projects() {
  return (
    <section className="section has-background-grey-lighter">
      <div className="container">
        <h1 className="title">Projects</h1>
        <h2 className="subtitle">
          Here are some of my personal projects
        </h2>
      </div>

      <div className="container is-max-desktop has-text-left">
        <Project 
          name="EtcordSharp"
          language="C# .NET Core"
          description="Etcord is a text and voice chat platform for communicating with friends. The project is still a work in progress."
          source="https://github.com/etkot/EtcordSharp" />

        <Project 
          name="Tetris"
          language="C++ Qt"
          description="Tetris made with Qt as a university project."
          source="https://github.com/HammerCar/Tetris" />

        <Project 
          name="Fractals"
          language="C++ OpenCL"
          description="Mandelbrot fractal generator made with C++ and OpenCL. Images are rendered on the GPU and then saved as a png file."
          source="https://github.com/HammerCar/Fractals" />
      </div>

      <div className="container">
        <h2 className="subtitle">
          Want to see more? Visit my GitHub!
        </h2>
        <a href="https://github.com/HammerCar" target="blank" className="button is-info"><FontAwesomeIcon icon={faGithub} className="mr-2" />GitHub</a>
      </div>
    </section>
  );
}

export default Projects;
